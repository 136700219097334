<template>
  <div>
    <div class="bg-white px-4 pt-4 -mb-1" v-if="model">
      <sqr-page-header :title="model.name">
        <sqr-button icon="edit" color="white" @click="rename()" />
        <sqr-button icon="trash" color="white" @click="removeConfirm()" />
      </sqr-page-header>
      <nav-tabs :tabs="tabs" class="-mx-4" />
    </div>
    <sqr-progress v-show="loading" />
    <sqr-error-banner :error="loadError" />
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrProgress from '@/sqrd-ui/SqrProgress';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import NavTabs from '@/components/NavTabs';
import broker from '../broker';
export default {
  name: 'InquiryModel',
  mixins: [broker],
  components: { SqrPageHeader, NavTabs, SqrProgress, SqrErrorBanner },
  props: {
    mid: String
  },
  computed: {
    tabs() {
      const bid = this.bid;
      const mid = this.mid;
      return [
        {
          id: 'questions',
          label: 'Questions',
          to: { name: 'inquiry-model-questions', params: { bid, mid } },
          exact: true
        },
        {
          id: 'recipients',
          label: 'Destinataires',
          to: { name: 'inquiry-model-recipients', params: { bid, mid } }
        },
        {
          id: 'email',
          label: "Modèle de message d'annonce",
          to: { name: 'inquiry-model-email', params: { bid, mid } }
        },
        {
          id: 'email-close',
          label: "Modèle de message de fin",
          to: { name: 'inquiry-model-email-close', params: { bid, mid } }
        }
      ];
    },
    ...mapState('inquiryModel', { model: 'doc' }),
    ...mapState('inquiryModel', ['loading', 'loadError', 'path'])
  },
  mounted() {
    this.sub({
      path: `brokers/${this.bid}/offer-models/${this.mid}`
    });
  },
  beforeDestroy() {
    this.unsub({});
  },
  methods: {
    ...mapActions('inquiryModel', ['sub', 'unsub']),
    ...mapActions('updater', ['update']),
    ...mapActions('remover', ['remove']),
    async rename() {
      const name = prompt('Renommer le modèle', this.model.name);
      if (name) {
        await this.update({ path: this.path, doc: { name } });
      }
    },
    async removeConfirm() {
      if (confirm(`Êtes-vous sûr de vouloir supprimer ${this.model.name}`)) {
        await this.remove({ path: this.path });
        this.$route({ name: 'inquiries-model', params: { bid: this.bid } });
      }
    }
  }
};
</script>
